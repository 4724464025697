import { createContext } from "react";
import type { GaiaData } from "gaia-data";
import type { SiteConfigSite } from "site-config-site";

import { CatalanaProductFeatures } from "shared/types";
import { SessionData, SessionStatus } from "@/services/amedia-user/login";
import colors from "@/theme/colors";
import { FetcherError } from "@/fetch/errors";
import { Locale } from "@/common/types";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";

const gaiaDefaults = {
  customerService: { phone: "32 27 70 55", email: "kundeservice@aid.no" },
  publicationColor: { primary: colors.amediaMagenta, secondary: "#303030" },
};
export interface AppContextInterface {
  site: SiteConfigSite;
  gaia: GaiaData;
  session: SessionData;
  subscriptions: {
    set: (subscriptions: Subscription[]) => void;
    data: Subscription[];
    error?: FetcherError | Error;
    setError: (error: FetcherError | Error) => void;
  };
  catalana: {
    productFeatures: CatalanaProductFeatures;
    setProductFeatures: (features: CatalanaProductFeatures) => void;
  };
}

export const defaultAppContext: AppContextInterface = {
  site: {
    domain: "www.tangotidende.no",
    name: { full: "Tangotidende", short: "Tangotidende" },
    locale: "nb" as Locale,
    participatesInPlussalt: true,
    onboardingMode: false,
    subscriptionSystem: {
      titleCode: undefined,
      netsParams: {},
    },
  },
  gaia: {
    publicationColor: {
      primary: gaiaDefaults.publicationColor.primary,
      secondary: gaiaDefaults.publicationColor.secondary,
    },
    customerService: {
      phone: gaiaDefaults.customerService.phone,
      email: gaiaDefaults.customerService.email,
    },
  },
  session: { status: SessionStatus.UNINITIALIZED },
  subscriptions: {
    set: () => ({}),
    data: [],
    setError: () => ({}),
  },
  catalana: {
    productFeatures: {},
    setProductFeatures: () => ({}),
  },
};

const AppContext = createContext<AppContextInterface>(defaultAppContext);

export default AppContext;
